

































import { EventModel } from 'tradingmate-modules/src/models/api/events'
import { Component, Prop, Vue } from 'vue-property-decorator'
import Utils from 'tradingmate-modules/src/Utils'
import Card from '@/components/layout/Card.vue'

@Component({
  components: {
    Card
  }
})
export default class Eventcard extends Vue {
  @Prop({ required: true })
  private readonly eventModel!: EventModel;

  get thumbnail (): string {
    let thumb = '/assets/images/placeholder/event.jpg'
    if (this.eventModel.Images?.length) {
      thumb = this.eventModel.Images[0].Tiny
    }
    return thumb
  }

  private isoToDisplayDate = Utils.isoToDisplayDate
  private isoToDisplayTime = Utils.isoToDisplayTime

  // getTime (startDate: string, endDate: string): string {
  //   const startTime = this.isoToDisplayTime(startDate)
  //   const endTime = this.isoToDisplayTime(endDate)
  //   if (startTime === endTime) {
  //     return startTime
  //   } else { return `${startTime} - ${endTime}` }
  // }

  getTime (startDateTime: string): string | null {
    const time = this.isoToDisplayTime(startDateTime)
    if (time === '00:00 am') {
      return null
    } else {
      return time
    }
  }

  getDate (start: string, end: string): string {
    const startDate = this.isoToDisplayDate(start)
    const endDate = this.isoToDisplayDate(end)
    if (startDate === endDate) {
      return startDate
    } else { return `${startDate} - ${endDate}` }
  }
}
