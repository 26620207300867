































import EventFilter from 'tradingmate-modules/src/filters/EventFilter'
import { PagedResultSet } from 'tradingmate-modules/src/models/api/common'
import {
  EventModel,
  EventPostStatus
} from 'tradingmate-modules/src/models/api/events'
import { Services } from 'tradingmate-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'
import EventCard from '@/components/collections/events/EventCard.vue'
import Page from '@/components/layout/Page.vue'
import Paging from '@/components/actions/Paging.vue'
import SectionSubtitle from '@/components/typography/SectionSubtitle.vue'

@Component({
  components: {
    EventCard,
    Page,
    Paging,
    SectionSubtitle
  }
})
export default class Events extends Vue {
  private loading = false
  private pagedEvents: PagedResultSet<EventModel> | null = null

  private loadingTmEvents = false
  private tmEvents: EventModel[] | null = null

  private eventFilter = new EventFilter({
    Page: 1,
    PageSize: 30,
    Status: EventPostStatus.Published
  })

  mounted (): void {
    this.updateFilter()
    this.getTmEvents()
    this.getEvents()
  }

  navToPage (nextPage: number): void {
    this.$router.push({ query: { page: nextPage.toString() } })
    this.updateFilter()
    this.getEvents()
  }

  updateFilter (): void {
    const queryPage = Number.parseInt(this.$route.query.page?.toString())
    if (!queryPage) return
    this.eventFilter.Page = queryPage
  }

  getTmEvents (): void {
    this.loadingTmEvents = true
    Services.API.Events.GetTmEvents()
      .then((results) => {
        this.tmEvents = results
      }).catch((error) => { console.error(error) })
      .finally(() => { this.loadingTmEvents = false })
  }

  getEvents (): void {
    this.loading = true
    Services.API.Events.GetEventsForPublic(this.eventFilter)
      .then(returnData => {
        this.pagedEvents = returnData
      }).finally(() => {
        this.loading = false
      })
  }
}
